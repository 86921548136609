@import url("https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap");

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.prompt-user {
  color: #3bb062;
}

.prompt-dir {
  color: #2f868e;
}

.prompt-dollar {
  margin-right: 10px;
}

.cmd-line > * {
  font-family: "Roboto Mono", monospace;
  font-size: 1em;
}

.cmd-line input {
  background: transparent;
  border: none;
  color: white;
}
